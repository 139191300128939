import React, { useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { auth } from "../firebase";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const FormItem = Form.Item;

const SignIn = () => {
  const intl = useIntl();
  const { isLoading, error, userLogin, setAuthUser } = useAuth();
  const [result, setResult] = useState("");
  const history = useHistory();

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    setResult("");
    userLogin(values, (err) => {
      if (err && err.message) {
        setResult(err.message);
      }
      console.log(err);
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className={`gx-app-logo-content-bg  gx-app-logo-content-bg-${process.env.REACT_APP_CORPORATE_ID}`}></div>

            <div className="gx-app-logo">
              <img alt="example" src={`/assets/images/corporates/${process.env.REACT_APP_CORPORATE_ID}.png`} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              {result && result !== "" && (
                <Form.Item>
                  <div class="ant-form-item-explain-error">
                    <div role="alert">{result}</div>
                  </div>
                </Form.Item>
              )}
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "page.login.invalid_username",
                    }),
                  },
                ]}
                name="email"
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: "page.login.username",
                  })}
                  type="text"
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: "page.login.please_enter_your_password",
                    }),
                  },
                ]}
                name="password"
              >
                <Input
                  type="password"
                  placeholder={intl.formatMessage({
                    id: "page.login.password",
                  })}
                />
              </Form.Item>
              {/* <Form.Item>
                <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span>
              </Form.Item> */}

              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="page.login.login" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          <AppNotificationContainer loading={isLoading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
