import React from "react";
import {Avatar, Popover} from "antd";
import {useAuth} from "../../authentication";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";

const UserProfile = () => {
  const {userSignOut,authUser} = useAuth();
  const history = useHistory();

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li>My Account</li>
      <li>Connections</li> */}
      <li onClick={onLogoutClick}><IntlMessages id="sidebar.logout" />
      </li>
    </ul>
  );

  return (
    <div className="gx-align-items-center  gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <span className="gx-avatar-name">{authUser?.displayName??authUser?.email}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
        <span>{authUser?.email??""}</span>
      </Popover>
    </div>
  )
};

export default UserProfile;
