import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "./params";

const DATA_COUNT = 12;
const COLLECTION_NAME = "NS_XSEN030_MONTHLY_BALANCE";

interface IBalanceData {
  balance: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IBalanceData = {
  balance: [],
  requested: false,
  loaded: false,
};

export function loadxsen030MonthlyBalance(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Xsen030MonthlyBalance.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.XSEN030_MONTHLY_BALANCE,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var balanceData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");
          balanceData.push({
            name: "balance",
            balance: doc.data().balance,
            dateTime: doc.data().dateTime.toDate(),
          });
        });

        balanceData = balanceData.reverse();
        dispatch({
          type: SENTIMENT_ACTION.XSEN030_MONTHLY_BALANCE,
          payload: {
            balance: balanceData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Xsen030MonthlyBalance(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case SENTIMENT_ACTION.XSEN030_MONTHLY_BALANCE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const xsen030MonthlyBalanceSelector = (state: any) =>
  state.Xsen030MonthlyBalance;
