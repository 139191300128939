import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { symbol } from "prop-types";

const DATA_COUNT = 1;
const COLLECTION_NAME = "NS_EXCHANGE";

interface IOrderbookData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IOrderbookData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadOrderbook(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {

    const state = getState();
    if (state.Orderbook.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.ORDERBOOK,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          var symbolData = doc.data().symbols;
          symbolData.forEach((e: any) => {
            var oBook = e.orderbook;
            var orderbookShort = e.orderbookShort;

            data.push({
              Symbol: e.symbol,
              Date: moment(doc.data().date.toDate()).format("DD.MM.YYYY HH:mm"),
              B_Buyers: oBook.buySide.greenTrades,
              S_Buyers: oBook.buySide.redTrades,
              T_Buyers: oBook.buySide.total,

              B_Sellers: oBook.sellSide.greenTrades,
              S_Sellers: oBook.sellSide.redTrades,
              T_Sellers: oBook.sellSide.total,

              T15_B_Buyers: orderbookShort.buySide.greenTrades,
              T15_S_Buyers: orderbookShort.buySide.redTrades,
              T15_T_Buyers: orderbookShort.buySide.total,

              T15_B_Sellers: orderbookShort.sellSide.greenTrades,
              T15_S_Sellers: orderbookShort.sellSide.redTrades,
              T15_T_Sellers: orderbookShort.sellSide.total,
            });
          });

        });


        dispatch({
          type: SENTIMENT_ACTION.ORDERBOOK,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Orderbook(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.ORDERBOOK:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const orderBookSelector = (state: any) => state.Orderbook;
