import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "./params";

const DATA_COUNT = 1000;
const COLLECTION_NAME = "NS_TRADES_30";

interface IXUData {
  trade30: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  trade30: [],
  requested: false,
  loaded: false,
};

const calcDuration = (open: any, close: any) => {
  //moment(close, "DD.MM.YYYY").diff(moment(open, "DD.MM.YYYY"), 'days');
  var startDate = moment(open, "DD.MM.YYYY").toDate();
  var endDate = moment(close, "DD.MM.YYYY").toDate();
  var nextDay = new Date(startDate);
  var cnt = 0;
  do {
    cnt += (nextDay.getDay() >= 1 && nextDay.getDay() <= 5) ? 1 : 0;
    nextDay.setDate(nextDay.getDate() + 1);
  } while (nextDay <= endDate);

  return cnt;
}

export function loadTrade30(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Trade30.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.TRADE_30,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var trade30Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          var opendt = doc.data().openDate.split(".");
          trade30Data.push({
            uid: doc.data().symbol + '_' + doc.data().openDate,
            name: "trade30",
            Index: doc.data().index,
            DataPackage: doc.data().dataPackage,
            Date: moment(doc.data().dateTime.toDate()).format("DD.MM.YYYY"),
            Symbol: doc.data().symbol,
            ODate: doc.data().openDate,
            CDate: doc.data().closeDate,
            OPrice: doc.data().openPrice,
            CPrice: doc.data().closePrice,
            PerDay: doc.data().perDay,
            Percent: doc.data().percent,
            Duration: doc.data().closeDate != "" ? calcDuration(doc.data().openDate, doc.data().closeDate) : 0,
            dateTime: doc.data().dateTime.toDate(),
            dateOpen: new Date(opendt[2], opendt[1] - 1, opendt[0]),
            IncomingSymbol : doc.data().incomingSymbol
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.TRADE_30,
          payload: {
            trade30: trade30Data.sort((a: any, b: any) => { return b.dateTime - a.dateTime || a.dateOpen - b.dateOpen }),
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Trade30(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.TRADE_30:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const trade30Selector = (state: any) => state.Trade30;
