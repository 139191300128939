import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import ImkbEndeksSenetler from "../../../util/ImkbEndeksSenetler";
import klineInterval from "./params";

const COLLECTION_NAME = "NS_XK_LINES_500";

interface IXUData {
  linesXK500: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
  selectedDate: Date | null;
  isWeekly: boolean;
}

const initialState: IXUData = {
  linesXK500: [],
  requested: false,
  loaded: false,
  lastUpdate: 0,
  selectedDate: null,
  isWeekly: false
};

export function loadLinesXK500Snapshot(firebase: any, period: number, count: number, isWeekly: boolean = false) {
  if (period < 0 || ![0, 10, 500, 99].includes(period)) {
    console.log("Invalid period for line XK500. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (new Date().getTime() - state.LinesXK30Snapshot.lastUpdate < 60 * 1000 && isWeekly == state.isWeekly) return;
    dispatch({
      type: SENTIMENT_ACTION.LINES_XK500_SNAPSHOT,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${isWeekly ? "_1W" : ""}_P${period}`)
      .orderBy("dateTime", "desc")
      .limit(count)
      .get().then((querySnapshot: any) => {
        var linesXK500Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {

          linesXK500Data.push({
            name: "linesXK500",
            Index: doc.data().index,
            Symbol: doc.data().symbol,
            Sentiment: doc.data().sentiment,
            Order: doc.data().order,
            ODate: doc.data().openDate,
            OPrice: doc.data().openPrice != "0" ? doc.data().openPrice : "",
            Price: doc.data().price,
            DailyChange: doc.data().dailyChange,
            Vbts: doc.data().vbts,
            First5to10: doc.data().first5to10,
            IsNewFirst5: doc.data().isNewFirst5,
            Viop: doc.data().viop,
            PerDay: doc.data().perDay,
            SentimentList: doc.data().sentimentList,
            PriceList: doc.data().priceList,
            ChangeList: doc.data().changeList,
            ReChangeList: doc.data().changeList.slice().reverse(),
            dateTime: doc.data().dateTime.toDate(),
            Time: doc.data().time ?? 0,
            UpdateTime: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy HH:mm"),
            LineDate: doc.data().dateTime.seconds,
            Indexes: ImkbEndeksSenetler.filter(x => x.name == doc.data().symbol)[0]?.val
          });
        });


        dispatch({
          type: SENTIMENT_ACTION.LINES_XK500_SNAPSHOT,
          payload: {
            linesXK500: linesXK500Data.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
            lastUpdate: new Date().getTime(),
            isWeekly : isWeekly
          },
        });
      });
  };
}

export default function LinesXK500Snapshot(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_XK500_SNAPSHOT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const linesXK500SnapshotSelector = (state: any) => state.LinesXK500Snapshot;
