import React, { useEffect, useState } from "react";
import { Layout, Button } from "antd";
import { UpOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import { ToastContainer } from 'react-toastify';

import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import { footerText } from "../../util/config";
import App from "../../routes/index";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import AppSidebar from "./AppSidebar";
import Customizer from "../Customizer";
import { Header } from "./Header";

const { Content, Footer } = Layout;

const getContainerClass = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return '';
  }
};

const getNavStyles = (navStyle,props) => {
  switch (navStyle) {
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return <HorizontalDefault />;
    case NAV_STYLE_DARK_HORIZONTAL:
      return <HorizontalDark />;
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return <InsideHeader />;
    case NAV_STYLE_ABOVE_HEADER:
      return <AboveHeader />;
    case NAV_STYLE_BELOW_HEADER:
      return <BelowHeader />;
    case NAV_STYLE_FIXED:
      return <Topbar {...props} />;
    case NAV_STYLE_DRAWER:
      return <Topbar {...props}  />;
    case NAV_STYLE_MINI_SIDEBAR:
      return <Topbar {...props}  />;
    case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
      return <NoHeaderNotification />;
    case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
      return <NoHeaderNotification />;
    default:
      return null;
  }
};

const MainApp = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [showSettings,setShowsSettings] = useState(false);
  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    })
  }, [dispatch]);

  const onToTop = () => {
    const divElement = document.getElementsByClassName('toptotop')[0];
    divElement.scrollIntoView(true);
  }

  return (
    <Layout className="gx-app-layout">
      
      <AppSidebar navStyle={navStyle} />
      <Layout>
      
        {getNavStyles(navStyle,{setShowsSettings})}
        <Header setShowsSettings={setShowsSettings}/>
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          <div className="toptotop"></div>
          <App match={match} />
          <Footer>
            <div className="gx-layout-footer-content">
              {footerText}
              <Button type="primary" shape="circle" onClick={onToTop}
                icon={<UpOutlined />}
                size={{ size: "large" }}
                style={{ position: 'absolute', bottom: 50, right: 50 }}
              />
            </div>
          </Footer>
        </Content>
      </Layout>
      <Customizer  showSettings={showSettings} setShowsSettings={setShowsSettings}/>
      <ToastContainer />
    </Layout>
  )
};
export default MainApp;

