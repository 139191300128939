import moment from "moment";
import ImkbEndeksSenetler, { ImportImkbEndeksSenetlerMap } from "../../../util/ImkbEndeksSenetler";

export const linesMapper = (doc: any) => {
  var sentiPoints = doc.sentimentList;
  var SentiPer = 0
  if (sentiPoints?.length > 0)
    SentiPer = ((sentiPoints[sentiPoints.length - 1] - sentiPoints[sentiPoints.length - 2]) / sentiPoints[sentiPoints.length - 2] * 100);

  var indexes = ImkbEndeksSenetler?.filter(x => x.name == doc.symbol)[0];

  return {
    Index: doc.index,
    Symbol: doc.symbol,
    Sentiment: doc.sentiment,
    Order: doc.order,
    Group: doc.group,
    ODate: doc.openDate,
    OPrice: doc.openPrice,
    Price: doc.price,
    DailyChange: doc.dailyChange,
    Vbts: doc.vbts,
    First5to10: doc.first5to10,
    IsNewFirst5: doc.isNewFirst5,
    Viop: doc.viop,
    PerDay: doc.perDay,
    SentimentList: doc.sentimentList,
    PriceList: doc.priceList,
    ChangeList: doc.changeList,
    ReChangeList: doc.changeList.slice().reverse(),
    dateTime: doc.dateTime.toDate(),
    Time: doc.time ?? 0,
    UpdateTime: moment
      .unix(doc.dateTime.seconds)
      .format("DD.MM.yy HH:mm"),
    LineDate: doc.dateTime.seconds,
    Indexes: (ImportImkbEndeksSenetlerMap as any)[doc.symbol]?.val,
    VWMA: doc.vwma?.reverse(),
    SentiPer: Math.round(SentiPer * 100) / 100,
  };

}