import React,{useState} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import {useAuth} from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { auth } from "../firebase";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const FormItem = Form.Item;

const ForgotPassword = () => {
  const intl=useIntl();
  const {isLoading, error, userLogin,setAuthUser} = useAuth();
  const [result,setResult]=useState("");
  const history = useHistory();

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    auth.sendPasswordResetEmail(values.email).then(()=>{
        setResult("Şifre sıfırlama e-maili gönderildi");
       })
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png"/>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <Form.Item> { result &&<div>{result}</div>}</Form.Item>

              <Form.Item
                rules={[{required: true, message: 'Geçersiz email adresi'}]} name="email">
                <Input placeholder="E-mail" type="email"/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="page.login.send_email"/>
                </Button>
                <span><IntlMessages id="page.login.or"/></span> <Link to="/signin"><IntlMessages id="page.login.login"/></Link> 
              </Form.Item>
            </Form>
          </div>
          <AppNotificationContainer loading={isLoading} error={error}/>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
