import { useState } from "react";
import { Menu, Dropdown, Button } from "antd";
import { createFromIconfontCN } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setDirectionRTL, switchLanguage } from "../../appRedux/actions";
import AppLocale from "../../lngProvider";

const LanguageMenu = (props: any) => {
  const dispatch = useDispatch();

  const intl = useIntl();
  const languageOptions = [
    {
      key: "tr",
      name: intl.formatMessage({ id: "settings.lang.turkish" }),
      flag: "🇹🇷",
    },
    {
      key: "az",
      name: intl.formatMessage({ id: "settings.lang.azerbaijani" }),
      flag: "🇦🇿",
    },
    {
      key: "en",
      name: intl.formatMessage({ id: "settings.lang.english" }),
      flag: "🇺🇸",
    },
    {
      key: "de",
      name: intl.formatMessage({ id: "settings.lang.german" }),
      flag: "🇩🇪",
    },
    {
      key: "ar",
      name: intl.formatMessage({ id: "settings.lang.arabic" }),
      flag: "🇸🇦",
    },
  ];
  const getInitialLanguage = () => {
    const key=localStorage.getItem("lang");
    const selected=languageOptions.find((item)=>item.key===key);
    if(selected)
    return selected;
    return languageOptions[0]
  }
  const [selectedLanguage, setSelectedLanguage] = useState(getInitialLanguage());

  const handleLanguageSelect = (language: any) => {
    const lang = language.key;
    localStorage.setItem("lang", lang);
    localStorage.setItem("isRTL", lang === "ar"? "true" : "false");
    if (lang === "ar") dispatch(setDirectionRTL(true));
    else dispatch(setDirectionRTL(false));

    dispatch(switchLanguage((AppLocale as any)[lang]));
    setSelectedLanguage(language);
  };

  const menu = (
    <Menu>
      {languageOptions.map((language) => (
        <Menu.Item
          key={language.name}
          onClick={() => handleLanguageSelect(language)}
        >
          {language.flag} {language.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown className="language-button" overlay={menu} trigger={["click"]}>
      <Button>{selectedLanguage.flag}</Button>
    </Dropdown>
  );
};

export default LanguageMenu;
