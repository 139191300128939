export const isStaging = () => {
  return process.env.REACT_APP_ENV === "staging";
};

export const isProd = () => {
  return process.env.REACT_APP_ENV === "prod";
};

export const getCompanyId = () => {
  return process.env.REACT_APP_COMPANY_ID;
}