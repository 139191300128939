import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  loadNotifications,
  notificationSelector,
} from "../../appRedux/reducers/sentiment/notifications";
import { useAuth } from "../../authentication";
import { firebaseApp } from "../../firebase";
import { useHistory } from "react-router-dom";
import { isProd } from "../../util/Env";
import { isCooperateUser } from "../../appRedux/reducers/sentiment/permissions";
import { nsUserSelector } from "../../appRedux/reducers/sentiment/user";
import { CORPORATES } from "../../constants/Corporates";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common?.pathname);
  const { authUser } = useAuth();

  useEffect(() => {
    dispatch(loadNotifications(firebaseApp, authUser?.uid));
  }, [authUser]);
  const notifications = useSelector(notificationSelector);
  const unreadNotifications =
    notifications && notifications?.data
      ? notifications?.data?.filter((n) => !n.read).length
      : 0;
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const { userSignOut } = useAuth();
  const history = useHistory();
  const onLogoutClick = () => {
    userSignOut(() => {
      history.push("/");
    });
  };

  const selectedKeys = pathname?.substr(1);
  const defaultOpenKeys = selectedKeys?.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="main">
              <Link to="/index/home">
                <i className="icon icon-home" />
                <span>
                  <IntlMessages id="sidebar.home" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="index">
              <Link to="/index/home">
                <span>
                  {" "}
                  <i className="icon icon-chart-line" />
                  <IntlMessages id="sidebar.index" />
                </span>
              </Link>
            </Menu.Item>

            {/* <Menu.SubMenu
              key="corporate"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <i className="icon icon-data-display" />
                  <IntlMessages id="sidebar.corporate" />
                </span>
              }
            >
              <Menu.Item key="bist/lines">
                <Link to="/bist/lines">
                  <i className="icon icon-widgets" />
                  <span>
                    <IntlMessages id="sidebar.bist.symbols" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/exploreCorp">
                <Link to="/bist/exploreCorp">
                  <i className="icon icon-search-new" />
                  <span>
                    <IntlMessages id="sidebar.bist.explorer" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/cosignals">
                <Link to="/bist/cosignals">
                  <i className="icon icon-megaphone" />
                  <span>
                    <IntlMessages id="sidebar.bist.first_list" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/cosignalsworst">
                <Link to="/bist/cosignalsworst">
                  <i className="icon icon-megaphone" />
                  <span>
                    <IntlMessages id="sidebar.bist.last_list" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/linesWeekly">
                <Link to="/bist/linesWeekly">
                  <i className="icon icon-widgets" />
                  <span>
                    <IntlMessages id="sidebar.corporate.weeklySymbols" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu> */}

            <Menu.SubMenu
              key="bist"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <i className="icon icon-chart-area" />
                  <IntlMessages id="sidebar.bist" />
                </span>
              }
            >
              <Menu.Item key="bist/all-lines">
                <Link to="/bist/all-lines">
                  <i className="icon icon-widgets" />
                  <span>
                    <IntlMessages id="sidebar.bist.symbols" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/signals">
                <Link to="/bist/signals">
                  <i className="icon icon-megaphone" />
                  <span>
                    <IntlMessages id="sidebar.bist.first_list" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/worst">
                <Link to="/bist/worst">
                  <i className="icon icon-down" />
                  <span>
                    <IntlMessages id="sidebar.bist.last_list" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/lines-weekly">
                <Link to="/bist/lines-weekly">
                  <i className="icon icon-widgets" />
                  <span>
                    Haftalık Semboller
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/signals-weekly">
                <Link to="/bist/signals-weekly">
                  <i className="icon icon-megaphone" />
                  <span>
                    Haftalık İlk 7 Listeleri
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/explore">
                <Link to="/bist/explore">
                  <i className="icon icon-search-new" />
                  <span>
                    <IntlMessages id="sidebar.bist.explorer" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/timeline">
                <Link to="/bist/timeline">
                  <i className="icon icon-map-clustering" />
                  <span>
                    Sembol Sinyal
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/follower">
                <Link to="/bist/follower">
                  <i className="icon icon-filter" />
                  <span>
                    Takipçi
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/mylines">
                <Link to="/bist/mylines">
                  <i className="icon icon-affix" />
                  <span>
                    Takip Listelerim
                  </span>
                </Link>
              </Menu.Item>

            </Menu.SubMenu>

            <Menu.SubMenu
              key="exchanger"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <i className="icon icon-chart-pie" />
                  <IntlMessages id="sidebar.exchanger" />
                </span>
              }
            >
              <Menu.Item key="exchanger/symbol">
                <Link to="/exchanger/symbol">
                  <i className="icon icon-extensions" />
                  <IntlMessages id="sidebar.exchanger.symbol" />
                </Link>
              </Menu.Item>

              <Menu.Item key="exchanger/corporate">
                <Link to="/exchanger/corporate">
                  <i className="icon icon-crm" />
                  <IntlMessages id="sidebar.exchanger.corporate" />
                </Link>
              </Menu.Item>
              <Menu.Item key="exchanger/signal">
                <Link to="/exchanger/signal">
                  <i className="icon icon-megaphone" />
                  <IntlMessages id="sidebar.exchanger.signals" />
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="perform"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <i className="icon icon-icon" />
                  <IntlMessages id="page.bist.explorer.performance" />
                </span>
              }
            >
              <Menu.Item key="bist/trades">
                <Link to="/bist/trades">
                  <i className="icon icon-pricing-table" />
                  <span>
                    <IntlMessages id="sidebar.bist.first_list_history" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="bist/shortTrades">
                <Link to="/bist/shortTrades">
                  <i className="icon icon-pricing-table" />
                  <span>
                    <IntlMessages id="sidebar.bist.last_list_history" />
                  </span>
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item key="account">
              <Link to="/account">
                <i className="icon icon-profile2" />
                <span>
                  <IntlMessages id="sidebar.account" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="notifications">
              <Link to="/notifications">
                <i className="icon icon-notification"></i>
                <span>
                  <IntlMessages id="sidebar.notifcations" />{" "}
                  {unreadNotifications > 0 && (
                    <span className="notification-count-badge">
                      {unreadNotifications}{" "}
                    </span>
                  )}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="bulletins">
              <Link to="/bulletins">
                <i className="icon icon-editor"></i>
                <span>Raporlar</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="help">
              <Link to="/help">
                <i className="icon icon-question-circle" />
                <span>
                  <IntlMessages id="sidebar.help" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="exit">
              <Link onClick={onLogoutClick}>
                <i className="icon icon-close-circle" />
                <span>
                  <IntlMessages id="sidebar.logout" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
        <UserProfile />
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
