import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 30;
const COLLECTION_NAME = "NS_DAILY_HIGHSCORES";

interface IHighScoresData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IHighScoresData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadDailyHighScores(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.DailyHighScores.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.DAILY_HIGHSCORES,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      //.orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          data.push({
            Date: moment(doc.data().date.toDate()).format("DD.MM.YYYY"),
            Symbols: doc.data().symbols
          });
        });


        dispatch({
          type: SENTIMENT_ACTION.DAILY_HIGHSCORES,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function DailyHighScores(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.DAILY_HIGHSCORES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const dailyHighScoresSelector = (state: any) => state.DailyHighScores;
