import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 365;
const COLLECTION_NAME = "NS_XU100_DAILY_BALANCE";

interface IBalanceData {
  balance: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IBalanceData = {
  balance: [],
  requested: false,
  loaded: false,
};

export function loadxu100DailyBalance(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Xu100DailyBalance.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.XU100_DAILY_BALANCE,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var balanceData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");
          balanceData.push({
            name: "balance",
            balance: doc.data().balance,
            dateTime: doc.data().dateTime.toDate(),
          });
        });

        balanceData = balanceData.reverse();
        dispatch({
          type: SENTIMENT_ACTION.XU100_DAILY_BALANCE,
          payload: {
            balance: balanceData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Xu100DailyBalance(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.XU100_DAILY_BALANCE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const xu100DailyBalanceSelector = (state: any) =>
  state.Xu100DailyBalance;
