import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 50;
const COLLECTION_NAME = "NS_EXCHANGE_MONEY";

interface IExchangeMoneySignalData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IExchangeMoneySignalData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadExchangeMoneySignal(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {

    const state = getState();
    if (state.ExchangeMoneySignal.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.EXCHANGE_MONEY_SIGNAL,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          data.push({
            code: doc.data().code,
            corporate: doc.data().corporate,
            date: moment(doc.data().date.toDate()).format("DD.MM.YY HH:mm"),
            money: doc.data().money,
            money5: doc.data().money5,
            money15: doc.data().money15,
            money30: doc.data().money30,
            money60: doc.data().money60,
            netper5: doc.data().netper5,
            netper15: doc.data().netper15,
            netper30: doc.data().netper30,
            netper60: doc.data().netper60,
          });

        });
        data = data.sort(function (a: any, b: any) {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;

          if (a.money > b.money) return -1;
          if (a.money < b.money) return 1;
          // Both idential, return 0
          return 0;
        });


        dispatch({
          type: SENTIMENT_ACTION.EXCHANGE_MONEY_SIGNAL,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function ExchangeMoneySignal(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.EXCHANGE_MONEY_SIGNAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const exchangeMoneySignalSelector = (state: any) => state.ExchangeMoneySignal;
